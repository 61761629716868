<template>
  <v-card flat>
    <v-card-title>{{ $t("notifications") }}</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="4">
          <v-card flat>
            <v-card-text>
              <v-form v-model="value" ref="form">
                <v-text-field
                  dense
                  v-model="form.title"
                  outlined
                  :label="$t('title')"
                  :placeholder="$t('title')"
                ></v-text-field>
                <v-text-field
                  dense
                  v-model="form.body"
                  outlined
                  :label="$t('subject')"
                  :placeholder="$t('subject')"
                ></v-text-field>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-btn
                text
                :loading="btnLoading"
                :disabled="!value"
                @click="submit"
              >
                {{ $t("save") }}
              </v-btn>
              <v-btn text @click="close">{{ $t("close") }}</v-btn>
            </v-card-actions>
          </v-card>
          <divider vertical></divider>
        </v-col>
        <v-col cols="8">
          <v-data-table></v-data-table>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "notifications",
  data() {
    return {
      value: false,
      btnLoading: false,
      form: {
        title: "",
        body: "",
      },
    };
  },
  computed: {
    name() {
      return this.data;
    },
  },

  methods: {
    submit() {},
    close() {},
  },
};
</script>

<style>
</style>